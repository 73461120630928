import React from "react";
import { Container, Row } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import childhoodOne from '../../Assets/DrawingMixedMedia/childhood story 1.jpg';
import childhoodTwo from '../../Assets/DrawingMixedMedia/childhood story 2.jpg';
import childhoodThree from '../../Assets/DrawingMixedMedia/childhood story 3.jpg';
import four from '../../Assets/DrawingMixedMedia/DSC_0179 copy.jpg';
import five from '../../Assets/DrawingMixedMedia/DSC_0195 copy.jpg';
import six from '../../Assets/DrawingMixedMedia/foolmoon 1.jpg';
import seven from '../../Assets/DrawingMixedMedia/foolmoon 2.jpg';
import eight from '../../Assets/DrawingMixedMedia/foolmoon 3.jpg';
import nine from '../../Assets/DrawingMixedMedia/foolmoon 4.jpg';
import pencilAndSpray from '../../Assets/DrawingMixedMedia/pencil and spray .jpg';
import plexi1 from '../../Assets/DrawingMixedMedia/plexi 1.jpg';
import plexi2 from '../../Assets/DrawingMixedMedia/plexi 2.jpg';
import plexi3 from '../../Assets/DrawingMixedMedia/plexi 3.jpg';
import plexi4 from '../../Assets/DrawingMixedMedia/plexi 4.jpg';
import plexi5 from '../../Assets/DrawingMixedMedia/plexi 5.jpg';
import plexi6 from '../../Assets/DrawingMixedMedia/plexi 6.jpg';
import plexi7 from '../../Assets/DrawingMixedMedia/plexi 7.jpg';
import plexi8 from '../../Assets/DrawingMixedMedia/plexi 8.jpg';
import darkMatter from '../../Assets/DrawingMixedMedia/untitled .jpg';

const childhoodStory = [
  {
    img: childhoodOne,
    title: 'spray paint on paper 39 x 30 cm. 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: childhoodTwo,
    title: 'spray paint on paper 39 x 30 cm. 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: childhoodThree,
    title: 'spray paint on paper 39 x 30 cm. 2021 ',
    author: 'Nikoletta Papakonstantinou',
  },
];

const fullMoon = [
  {
    img: six,
    title: 'mixed media 39 x 30 cm. 2022',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: seven,
    title: 'mixed media 39 x 30 cm. 2022',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: eight,
    title: 'mixed media 39 x 30 cm. 2022',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: nine,
    title: 'mixed media 39 x 30 cm. 2022',
    author: 'Nikoletta Papakonstantinou',
  },
]

const treeAndRoots = [
  {
    img: plexi1,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi2,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi3,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi4,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi5,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi6,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi7,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: plexi8,
    title: 'Print on paper and plastic membrane attached on plexiglass 14 x 21cm, 2021',
    author: 'Nikoletta Papakonstantinou',
  },
]

const further = [
  {
    img: five,
    title: 'Untitled, Acrylic paint and oil on canvas, 2015',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: four,
    title: 'mixed media 39 x 30 cm. 2022',
    author: 'Nikoletta Papakonstantinou',
  },
  
  {
    img: pencilAndSpray,
    title: 'Untitled, Spray paint and pencil on paper, 65 x 50cm,  2021  ',
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: darkMatter,
    title: 'Untitled, Acrylic paint on Canvas,  60x 60 cm, 2018  ',
    author: 'Nikoletta Papakonstantinou',
  },
]

function DrawingMixedMedia() {

  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage1, setCurrentImage1] = React.useState(0);
  const [isViewerOpen1, setIsViewerOpen1] = React.useState(false);
  const [currentImage2, setCurrentImage2] = React.useState(0);
  const [isViewerOpen2, setIsViewerOpen2] = React.useState(false);
  const [currentImage3, setCurrentImage3] = React.useState(0);
  const [isViewerOpen3, setIsViewerOpen3] = React.useState(false);
  
  const childhoodStory1 = [
    childhoodOne,
    childhoodTwo,
    childhoodThree,
  ]

  const fullMoon1 = [
    six,
    seven,
    eight,
    nine,
  ]

  const treeAndRoots1 = [
    plexi1,
    plexi2,
    plexi3,
    plexi4,
    plexi5,
    plexi6,
    plexi7,
    plexi8,
  ]

  const further1 = [
    five,
    four,
    pencilAndSpray,
    darkMatter
  ]

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const openImageViewer1 = React.useCallback((index) => {
    setCurrentImage1(index);
    setIsViewerOpen1(true);
  }, []);

  const closeImageViewer1 = () => {
    setCurrentImage1(0);
    setIsViewerOpen1(false);
  };

  const openImageViewer2 = React.useCallback((index) => {
    setCurrentImage2(index);
    setIsViewerOpen2(true);
  }, []);

  const closeImageViewer2 = () => {
    setCurrentImage2(0);
    setIsViewerOpen2(false);
  };

  const openImageViewer3 = React.useCallback((index) => {
    setCurrentImage3(index);
    setIsViewerOpen3(true);
  }, []);

  const closeImageViewer3 = () => {
    setCurrentImage3(0);
    setIsViewerOpen3(false);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
        Childhood <strong className="purple">story </strong>
        </h1>
        <p style={{ color: "black" }}>
          This short series is inspired by an early childhood story in which the moon is visiting “our house” every night in order to drink milk and get some rest till the morning comes. This story’s been crucial for Nikoletta ’s work as it represents her belief that seeking into our early memories can bring up very unique and original ideas. 
          That’s why she chose to bring to life such incidents and stories by protecting them and carrying them around, as a significant part of her inner self.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {childhoodStory.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={childhoodStory1}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
      <Container>
        <h1 className="project-heading">
        The full moon <strong className="purple">series </strong>
        </h1>
        <p style={{ color: "black" }}>
          Based on the “childhood story” I created some enlighten works of art with the metal flag as an addition,
          which in this case, as a danger symbol, illustrates  the fear towards adulthood and it’s uncertainty. 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {fullMoon.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer1(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen1 && (
        <ImageViewer
          src={fullMoon1}
          currentIndex={currentImage1}
          onClose={closeImageViewer1}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
      <Container>
        <h1 className="project-heading">
        The tree and his <strong className="purple">roots </strong>
        </h1>
        <p style={{ color: "black" }}>
        The idea behind this series is the accidental co-occurrence of two concepts: 
        reality and its distant memory;
        the actual incidents we experienced long ago and their impression on us through the years;
        the smell, the shape or the color of the flowers, which might be less bright and blue than the ones we were able to see when we were young. 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {treeAndRoots.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer2(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen2 && (
        <ImageViewer
          src={treeAndRoots1}
          currentIndex={currentImage2}
          onClose={closeImageViewer2}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
      <Container>
        <h1 className="project-heading">
        Further <strong className="purple">works </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {further.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer3(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen3 && (
        <ImageViewer
          src={further1}
          currentIndex={currentImage3}
          onClose={closeImageViewer3}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
    </Container>
  );
}

export default DrawingMixedMedia;