import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillMail,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import one from '../../Assets/photography/1.jpg';
import sinking from '../../Assets/DrawingMixedMedia/untitled .jpg';
import rafi from '../../Assets/SoloExhibition/1.jpg';
import book from '../../Assets/Zine/thumbnail.jpg';
import mikroula from '../../Assets/GraphicStorytelling/4.jpg';
import characterThumb from '../../Assets/CharacterBasedDrawings/1.jpg';
import lifeDrawingThumbnail from '../../Assets/LifeDrawing/thethumbnail.jpg'

function Home2() {

  const handleEmail = () => {
    window.open('mailto:nikolettap396@gmail.com?subject=Subject&body=Body%20goes%20here');
  }

  return (
    <Container fluid className="home-about-section" id="about" style={{padding: '0px'}}>
      <Container className="container-about">
        <Row
          as={Link}
          to="/lifeDrawing"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description" style={{padding: '0px', background: '#fcba03'}}>
          <div><img className="section-img" src={lifeDrawingThumbnail} alt="waving laundry"></img></div>
          <div className="tile-description" style={{paddingTop: '6%'}}>
            Life drawings
            <p style={{fontWeight: '200', paddingTop: '2%'}}></p>
          </div>
          </Col> 
        </Row>
        <Row 
          as={Link} 
          to="/character-based-drawing"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description-right" style={{padding: '0px', background: '#6bdcc5'}}>
              <div><img className="section-img-right" src={characterThumb} alt="MomA Art"></img></div>
              <div className="tile-description" style={{paddingTop: '6%'}}>
                Character based drawings 
              <p style={{fontWeight: '200', paddingTop: '2%'}}></p>
              </div>
          </Col> 
        </Row>
        <Row
          as={Link}
          to="/graphic-storytelling"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description" style={{padding: '0px', background: '#ff585d'}}>
            <div><img className="section-img" src={mikroula} alt="MomA Art"></img></div>
            <div className="tile-description" style={{paddingTop: '6%'}}>Graphic storytelling</div>
          </Col> 
        </Row>
        <Row
          as={Link}
          to="/zines"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description-right" style={{padding: '0px', background: '#ffa096'}}>
            <div><img className="section-img-right" src={book} alt="Book cover"></img></div>
            <div className="tile-description" style={{paddingTop: '6%'}}>Zines | Artbooks</div>
          </Col> 
        </Row>
        <Row
          as={Link}
          to="/solo-exhibition"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description" style={{padding: '0px', background: '#00afd7'}}>
            <div><img className="section-img" src={rafi} alt="MomA Art"></img></div>
            <div className="tile-description" style={{paddingTop: '6%'}}>
                About Intimacy
              <p style={{fontWeight: '200', paddingTop: '2%'}}>Solo Exhibition</p>
            </div>
          </Col> 
        </Row>
        <Row
          as={Link}
          to="/drawing-painting"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description-right" style={{padding: '0px', background: '#ff8f1c'}}>
          <div><img className="section-img-right" src={sinking} alt="MomA Art"></img></div>
          <div className="tile-description" style={{paddingTop: '6%'}}>Painting-Mixed Media</div>
          </Col> 
        </Row>
        <Row
          as={Link}
          to="/photography"
          style={{textDecoration: 'none'}}
        >
          <Col md={8} className="home-about-description" style={{padding: '0px', background: 'rgb(0 -5 0 / 1)'}}>
          <div><img className="section-img" src={one} alt="waving laundry"></img></div>
          <div className="tile-description" style={{paddingTop: '6%', color: '#ffffff'}}>
            Photography
            <p style={{fontWeight: '200', paddingTop: '2%'}}></p>
          </div>
          </Col> 
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>            
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://instagram.com/nikolettappkw?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/nikoletta-papakonstantinou-6a402a1b6/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="sw"
                  onClick={handleEmail}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
