import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import LifeDrawing from "./components/LifeDrawing/LifeDrawing";
import GraphicStorytelling from "./components/GraphicStorytelling/GraphicStorytelling";
import SoloExhibition from "./components/SoloExhibition/SoloExhibition";
import Zines from "./components/Zines/zines";
import DrawingMixedMedia from "./components/DrawingMixedMedia/DrawingMixedMedia";
import Photography from "./components/Photography/Photography";
import CharacterBasedDrawing from "./components/CharacterBasedDrawing/CharacterDrawing"
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Animation from "./components/Animation/Animation";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lifeDrawing" element={<LifeDrawing />} />
          <Route path="/graphic-storytelling" element={<GraphicStorytelling />} />
          <Route path="/zines" element={<Zines />} />
          <Route path="/solo-exhibition" element={<SoloExhibition />} />
          <Route path="/drawing-painting" element={<DrawingMixedMedia />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/character-based-drawing" element={<CharacterBasedDrawing />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/animation" element={<Animation />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
