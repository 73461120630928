import React from "react";
import { Container, Row } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import one from '../../Assets/GraphicStorytelling/1.jpg';
import two from '../../Assets/GraphicStorytelling/2.jpg';
import three from '../../Assets/GraphicStorytelling/3.jpg';
import four from '../../Assets/GraphicStorytelling/4.jpg';
import five from '../../Assets/GraphicStorytelling/5.jpg';
import six from '../../Assets/GraphicStorytelling/6.jpg';
import seven from '../../Assets/GraphicStorytelling/7.jpg';
import eight from '../../Assets/GraphicStorytelling/8.jpg';
import nine from '../../Assets/GraphicStorytelling/9.jpg';
import ten from '../../Assets/GraphicStorytelling/10.jpg';
import eleven from '../../Assets/GraphicStorytelling/11.jpg';
import twelve from '../../Assets/GraphicStorytelling/12.jpg';
import thirteen from '../../Assets/GraphicStorytelling/13.jpg';
import fourteen from '../../Assets/GraphicStorytelling/14.jpg';
import fitheteen from '../../Assets/GraphicStorytelling/15.jpg';
import sixteen from '../../Assets/GraphicStorytelling/16.jpg';

function GraphicStorytelling() {

  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const images = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fitheteen,
    sixteen
  ];

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "black" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {images.map((src, index) => (
        <ImageListItem key={src}>
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
        </ImageListItem>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
    </Container>
  );
}

export default GraphicStorytelling;