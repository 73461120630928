import React from "react";
import { Container, Row } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import one from '../../Assets/LifeDrawing/1.jpg';
import two from '../../Assets/LifeDrawing/2.jpg';
import three from '../../Assets/LifeDrawing/3.jpg';
import four from '../../Assets/LifeDrawing/4.jpg';
import five from '../../Assets/LifeDrawing/5.jpg';
import six from '../../Assets/LifeDrawing/6.jpg';
import seven from '../../Assets/LifeDrawing/7.jpg';
import eight from '../../Assets/LifeDrawing/8.jpg';
import nine from '../../Assets/LifeDrawing/9.jpg';
import ten from '../../Assets/LifeDrawing/10.jpg';
import eleven from '../../Assets/LifeDrawing/11.jpg';
import twelve from '../../Assets/LifeDrawing/12.jpg';
import thirteen from '../../Assets/LifeDrawing/aftertheportraiwthglasses.jpg';
import fourteen from '../../Assets/LifeDrawing/lasone.jpg';

const images = [
  {
    img: three,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: two,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: one,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: four,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: five,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: six,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: seven,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: eight,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: nine,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: ten,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: thirteen,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: eleven,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: twelve,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: fourteen,
    author: 'Nikoletta Papakonstantinou',
  },
];

function LifeDrawing() {

  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  
  const images1 = [
    three,
    two,
    one,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    thirteen,
    eleven,
    twelve,
    fourteen
  ];

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
        Life Drawing <strong className="purple">-Studies </strong>
        </h1>
        <p style={{ color: "black" }}>
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {images.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images1}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
    </Container>
  );
}

export default LifeDrawing;