import React from "react";
import { Container, Row } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import one from '../../Assets/photography/1.jpg';
import two from '../../Assets/photography/2.jpg';
import three from '../../Assets/photography/3.jpg';
import four from '../../Assets/photography/4.jpg';
import five from '../../Assets/photography/5.jpg';
import six from '../../Assets/photography/6.jpg';
import seven from '../../Assets/photography/7.jpg';
import eight from '../../Assets/photography/8.jpg';
import nine from '../../Assets/photography/9.jpg';
import cyano1 from '../../Assets/photography/cyanotype 1.jpg';
import cyano2 from '../../Assets/photography/cyanotype 2 .jpg';
import cyano3 from '../../Assets/photography/cyanotype 3 .jpg';
import cyano4 from '../../Assets/photography/cyanotype 4 .jpg';
import cyano5 from '../../Assets/photography/cyanotype 5 .jpg';

const images = [
  {
    img: three,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: two,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: one,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: four,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: five,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: six,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: seven,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: eight,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: nine,
    author: 'Nikoletta Papakonstantinou',
  },
];

const cyanoTypes = [
  {
    img: cyano1,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano2,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano3,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano4,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano5,
    author: 'Nikoletta Papakonstantinou',
  },
]

function Photography() {

  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage1, setCurrentImage1] = React.useState(0);
  const [isViewerOpen1, setIsViewerOpen1] = React.useState(false);
  
  const images1 = [
    three,
    two,
    one,
    four,
    five,
    six,
    seven,
    eight,
    nine,
  ];

  const cyanoTypes1 = [
    cyano1,
    cyano2,
    cyano3,
    cyano4,
    cyano5
  ]

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const openImageViewer1 = React.useCallback((index) => {
    setCurrentImage1(index);
    setIsViewerOpen1(true);
  }, []);

  const closeImageViewer1 = () => {
    setCurrentImage1(0);
    setIsViewerOpen1(false);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
        Neighbourhood series <strong className="purple">-2020 </strong>
        </h1>
        <p style={{ color: "black" }}>
          Photos of my neighbourhood that I took with my D- SLR camera during lockdown.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {images.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images1}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
      <Container>
        <h1 className="project-heading">
        Cyanotypes on paper <strong className="purple">-2018 </strong>
        </h1>
        <p style={{ color: "black" }}>
          Photos of some random items that I collected and composed during my Erasmus studies in the Hague.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {cyanoTypes.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer1(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen1 && (
        <ImageViewer
          src={cyanoTypes1}
          currentIndex={currentImage1}
          onClose={closeImageViewer1}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
    </Container>
  );
}

export default Photography;