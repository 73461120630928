import React from "react";
import { Container, Row } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import one from '../../Assets/CharacterBasedDrawings/1.jpg';
import two from '../../Assets/CharacterBasedDrawings/2.jpg';
import three from '../../Assets/CharacterBasedDrawings/3.jpg';
import four from '../../Assets/CharacterBasedDrawings/4.jpg';
import five from '../../Assets/CharacterBasedDrawings/5.jpg';
import six from '../../Assets/CharacterBasedDrawings/6.jpg';
import seven from '../../Assets/CharacterBasedDrawings/7.jpg';
import eight from '../../Assets/CharacterBasedDrawings/8.jpg';
import nine from '../../Assets/CharacterBasedDrawings/9.jpg';
import ten from '../../Assets/CharacterBasedDrawings/10.jpg';
import eleven from '../../Assets/CharacterBasedDrawings/11.jpg';
import twelve from '../../Assets/CharacterBasedDrawings/12.jpg';
import ni from '../../Assets/CharacterBasedDrawings/ni.jpg';
import pi from '../../Assets/CharacterBasedDrawings/pikipi.jpg';

function CharacterBasedDrawings() {

  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const images = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ni,
    pi,
    ten,
    eleven,
    twelve
  ];

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Digital <strong className="purple">paintings and Drawings on paper </strong>
        </h1>
        <p style={{ color: "black" }}>
          All with a sense of humor.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <ImageList>
      {images.map((src, index) => (
        <ImageListItem key={src}>
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
        </ImageListItem>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
    </Container>
  );
}

export default CharacterBasedDrawings;