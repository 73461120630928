import React from "react";
import ReactPlayer from "react-player";
import { Container, Row } from "react-bootstrap";

function Animation() {

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Animation <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "black" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        </Row>
      </Container>
      <div className="videoFrame">
        <iframe 
          // extra link for a shwocase https://player.vimeo.com/video/785634454?h=bced53bdcd
          src="https://player.vimeo.com/video/847794884?h=fdbff92c10&amp;badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          width={'100%'}
          height={'360px'}
          allow="autoplay; fullscreen; picture-in-picture" 
          style={{position: "relative", padding: "0 0 0 0"}}
          title="pedi"
        />
        </div>
          <p style={{ color: "black", padding: "0 0 50px 0" }}>
            Mikroula first animation Black & White.
          </p>
      <div className="videoFrame">
        <iframe 
          // extra link for a shwocase https://player.vimeo.com/video/785634454?h=bced53bdcd
          src="https://player.vimeo.com/video/844269269?h=fdbff92c10&amp;badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          width={'100%'}
          height={'360px'}
          allow="autoplay; fullscreen; picture-in-picture" 
          style={{position: "relative", padding: "0 0 0 0"}}
          title="pedi"
        />
      </div>
        <p style={{ color: "black", padding: "0 0 50px 0" }}>
          2D animated Teddy Bear walking.
        </p>
      <div className="videoFrame">
        <iframe 
          // extra link for a shwocase https://player.vimeo.com/video/785634454?h=bced53bdcd
          src="https://player.vimeo.com/video/829593487?h=8914a07ed3&amp;badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          width={'100%'}
          height={'360px'}
          allow="autoplay; fullscreen; picture-in-picture" 
          style={{position: "relative", padding: "0 0 0 0"}}
          title="pedi"
        />
      </div>
        <p style={{ color: "black", padding: "0 0 50px 0" }}>
          Simple turn of the head frame by frame 2D Animation.
        </p>
      <div className="videoFrame">
        <iframe 
          // extra link for a shwocase https://player.vimeo.com/video/785634454?h=bced53bdcd
          src="https://player.vimeo.com/video/824722297?h=a47fb444a5&amp;badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          width={'100%'}
          height={'360px'}
          allow="autoplay; fullscreen; picture-in-picture" 
          style={{position: "relative", padding: "0 0 0 0"}}
          title="duck"
        />
      </div>
        <p style={{ color: "black", padding: "0 0 50px 0" }}>
          My first walking circle. Frame by frame 2D Animation.
        </p>
      <div className="videoFrame">
        <iframe 
          // extra link for a shwocase https://player.vimeo.com/video/785634454?h=bced53bdcd
          src="https://player.vimeo.com/video/822370328?h=14b7569200&amp;badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          width={'100%'}
          height={'360px'}
          allow="autoplay; fullscreen; picture-in-picture;" 
          style={{position: "relative", padding: "0 0 0 0"}}
          title="Owl"
        />
      </div>
        <p style={{ color: "black", padding: "0 0 50px 0" }}>
          Flying Owl. Frame by frame 2D Animation.
        </p>
      <div className="videoFrame">
      <iframe 
          // extra link for a shwocase https://player.vimeo.com/video/785634454?h=bced53bdcd
          src="https://player.vimeo.com/video/787905588?h=14b7569200&amp;badge=0&amp;autopause=0&amp;loop=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          width={'100%'}
          height={'360px'}
          allow="autoplay; fullscreen; picture-in-picture" 
          style={{position: "relative", padding: "0 0 0 0"}}
          title="fantasma"
        />
      </div>
        <p style={{ color: "black", padding: "0 0 50px 0" }}>
          My very first attempt to animate something. Frame by frame 2D Animation/ rigging.
        </p>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Container>
  );
}

export default Animation;