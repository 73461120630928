import React from "react";
import { Container, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import one from '../../Assets/Zine/1.jpg';
import two from '../../Assets/Zine/2.jpg';
import three from '../../Assets/Zine/3.jpg';
import four from '../../Assets/Zine/4.jpg';
import five from '../../Assets/Zine/1HTY.jpg';
import six from '../../Assets/Zine/2FB S.jpg';
import seven from '../../Assets/Zine/9.jpg';
import eight from '../../Assets/Zine/10.jpg';
import nine from '../../Assets/Zine/5.jpg';
import ten from '../../Assets/Zine/8.jpg';
import eleven from '../../Assets/Zine/11.jpg';
import twelve from '../../Assets/Zine/12.jpg';
import thirteen from '../../Assets/Zine/15.jpg';
import fourteen from '../../Assets/Zine/16.jpg';
import fithteen from '../../Assets/Zine/5A.jpg';
import sixteen from '../../Assets/Zine/16.jpg';
import seventeen from '../../Assets/Zine/17.jpg';
import eighteen from '../../Assets/Zine/18.jpg';

function Zines() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Zines | Artbooks </strong>
        </h1>
        <p style={{ color: "white" }}>
          Part of my solo exhibition "About Intimacy"
        </p>
        <p>Our home, the stories we have been told and the ones we may tell to others. In this exhibition I am trying to depict my own personal infinite effort to prottect incidents or feelings that are likely to get lost and forgotten in time. 
 Acrylic and mixed media paintings, together with some small- scale installations create an environment of reconciliation with all kinds of memories that shape us. The memories we achieved to embrace and the ones that we are still trying to accept as inextricable parts of our selves.  
</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Carousel style={{width: "100%", minHeight: "auto", maxHeight: "auto", zIndex: "100"}}>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={one}
              alt="First slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={two}
              alt="Second slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={three}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={four}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={five}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={six}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={seven}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={eight}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={nine}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={ten}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={eleven}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={twelve}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={thirteen}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={fourteen}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={fithteen}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={sixteen}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={seventeen}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{width: "100%", minHeight: "auto", maxHeight: "691px", zIndex: "100"}}
              src={eighteen}
              alt="Third slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        </Row>
      </Container>
    </Container>
  );
}

export default Zines;