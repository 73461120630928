import React from "react";
import { Container, Row } from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import one from '../../Assets/SoloExhibition/1.jpg';
import two from '../../Assets/SoloExhibition/2.jpg';
import three from '../../Assets/SoloExhibition/3.jpg';
import four from '../../Assets/SoloExhibition/4.jpg';
import five from '../../Assets/SoloExhibition/5.jpg';
import seven from '../../Assets/SoloExhibition/7.jpg';
import eight from '../../Assets/SoloExhibition/8.jpg';
import nine from '../../Assets/SoloExhibition/9.jpg';
import cyano1 from '../../Assets/SoloExhibition/10.jpg';
import cyano3 from '../../Assets/SoloExhibition/13.jpg';
import cyano4 from '../../Assets/SoloExhibition/14.jpg';
import cyano5 from '../../Assets/SoloExhibition/15.jpg';

const images = [
  {
    img: one,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: two,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: three,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: four,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: five,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: seven,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: eight,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: nine,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano1,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano3,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano4,
    author: 'Nikoletta Papakonstantinou',
  },
  {
    img: cyano5,
    author: 'Nikoletta Papakonstantinou',
  },
];

function SoloExhibition() {
  
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  
  const images1 = [
    one,
    two,
    three,
    four,
    five,
    seven,
    eight,
    nine,
    cyano1,
    cyano3,
    cyano4,
    cyano5
  ];

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
        About intimacy <strong className="purple">Solo Exhibition </strong>
        </h1>
        <p style={{ color: "black" }}>
        "Our home, the stories we have been told and the ones we may tell to others.
        In this exhibition I am trying to depict my own personal infinite effort to prottect incidents or feelings that are likely to get lost and forgotten in time. 
        Acrylic and mixed media paintings, together with some small- scale installations create an environment of reconciliation with all kinds of memories that shape us. 
        The memories we achieved to embrace and the ones that we are still trying to accept as inextricable parts of our selves."
        </p>
        <p>Nikoletta Papakonstantinou</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}></Row>
      </Container>
      <ImageList>
      {images.map((src, index) => (
        <ImageListItem key={src.img}>
        <img
          src={src.img}
          onClick={() => openImageViewer(index)}
          key={index}
          style={{ padding: "10px"}}
          alt=""
        />
          <ImageListItemBar
              title={src.title}
              subtitle={<span>by: {src.author}</span>}
              position="below"
          />
        </ImageListItem>
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images1}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
      </ImageList>
    </Container>
  );
}

export default SoloExhibition;