import React from "react";
import { Container } from "react-bootstrap";
import Home2 from "./Home2";
import Type from "./Type";
 

function Home() {
  return (
    <section>
    
      <Container fluid className="home-section" id="home">
        <div style={{float: 'left'}}>
          <Type></Type>
        </div>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
